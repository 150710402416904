<template>
    <vx-card :title="'Edit Handover Stock Relocation Items'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>STO</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="stoCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Handover Document</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="handoverCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Source Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="this.srcWarehouse ? this.srcWarehouse.code + ' / ' + this.srcWarehouse.name : 'Loading..'" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Dest. Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="this.destWarehouse ? this.destWarehouse.code + ' / ' + this.destWarehouse.name : 'Loading..'" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Vehicle Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Vehicle 
                    :value="this.selectedVehicle" 
                    @selected="(val) => {this.selectedVehicle = val}"
                ></Vehicle>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Driver</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Driver 
                    :value="this.selectedDriver"
                    @selected="(val) => {this.selectedDriver = val}"
                ></Driver>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Handover Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <datepicker
                    v-model="handoverDate"
                    format="yyyy-MM-dd"
                    input-class="input-class"
                    :disabled-dates="disabledDates"
                ></datepicker>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-table stripe border style="width:100%" :data="dataLines">
                    <template slot="thead">
                        <vs-th>Delivery Note Data</vs-th>
                        <vs-th>Item Name</vs-th>
                        <vs-th>HU</vs-th>
                        <vs-th>Quantity</vs-th>
                        <vs-th>Dest. Address</vs-th>
                        <vs-th><vs-checkbox @input="addAllChecked" v-model="checkedAll"></vs-checkbox>Handed-over</vs-th>
                    </template>

                    <template slot-scope="">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in dataLines">
                            <vs-td>{{ tr.delivery_note_data }}</vs-td>
                            <vs-td>{{ tr.item_name }}</vs-td>
                            <vs-td>{{ tr.item_unit }}</vs-td>
                            <vs-td>{{ tr.amount }}</vs-td>
                            <vs-td>{{ tr.dest_address }}</vs-td>
                            <vs-td>
                                <vs-checkbox
                                    @input="addChecked(indextr, tr.id)"
                                    v-model="tr.is_handedover"
                                ></vs-checkbox>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mr-3 mb-2" v-on:click="handleSave">Save</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import moment from "moment";
import Vehicle from "./component/Vehicle.vue"
import Driver from "./component/Driver.vue"
import Datepicker from 'vuejs-datepicker';

export default {
    components: {
        Vehicle,
        Driver,
        Datepicker,
    },
    data() {
        return {
            id: null,
            readonly: true,
            stoCode: null,
            handoverCode: null,
            handoverDate: null,
            disabledDates: {
                to: new Date(new Date().setDate(new Date().getDate() - 1)) // Semua tanggal sebelum hari ini akan dinonaktifkan
            },
            dataLines: [
                {
                    id: 0,
                    delivery_note_data: "",
                    item_name: "",
                    item_unit: "",
                    amount: 0,
                    dest_address: "",
                    is_handedover: false,
                }
            ],
            checkedAll: false,
            checked: [],
            selectedVehicle: null,
            selectedDriver: null,
            srcWarehouse: null,
            destWarehouse: null,
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http.get("/api/wms/v1/outbound/sto-processing/handover-edit/" + this.id)
            .then((resp) => {
                if (resp.code == 200) {
                    this.stoCode = resp.data.sto_code;
                    this.handoverCode = resp.data.handover_code;
                    this.selectedVehicle = resp.data.vehicle;
                    this.selectedDriver = resp.data.driver;
                    this.srcWarehouse = resp.data.src_warehouse;
                    this.destWarehouse = resp.data.dest_warehouse;
                    this.handoverDate = resp.data.handover_date;
                    this.dataLines = resp.data.data_lines;
                    let handovered = []
                    this.dataLines.forEach((val) => {
                        if (val.is_handedover) {
                            this.checked.push(val.id)
                            handovered.push(val.id)
                        }
                    })
                    if (this.dataLines.length == handovered.length) {
                        this.checkedAll = true
                    }
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        addAllChecked() {
            if (this.checkedAll) {
                this.checked = []
                this.dataLines.forEach(val => {
                    val.is_handedover = this.checkedAll
                    this.checked.push(val.id)
                })
            } else {
                this.checked = []
                this.dataLines.forEach(val => {
                    val.is_handedover = this.checkedAll
                })
            }
        },
        addChecked(index, val) {
            if (this.dataLines[index].is_handedover) {
                this.checked.push(val)
            } else {
                this.checked.splice(this.checked.indexOf(val), 1);
            }

            if (this.checked.length == this.dataLines.length) {
                this.checkedAll = true
            } else {
                this.checkedAll = false
            }
        },
        handleSave() {
            if (!this.selectedVehicle) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select Vehicle!",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.selectedDriver) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select Driver!",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to update this Handover Document?",
                accept: () => {
                    this.$vs.loading()
                    this.$http.post("/api/wms/v1/outbound/sto-processing/save-handover/" + this.id, {
                        selected_vehicle: this.selectedVehicle.id,
                        selected_driver: this.selectedDriver.id,
                        handover_date: this.formatDate(this.handoverDate),
                        line_ids: this.checked,
                    })
                    .then(resp => {
                        if (resp.code == 200) {
                            this.$vs.notify({
                                title: "Success",
                                text: "",
                                color: "success",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-check"
                            });
                            this.$vs.loading.close();
                            this.handleBack();
                        } else {
                            this.$vs.notify({
                                title: "Danger",
                                text: resp.message,
                                color: "danger",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-check"
                            });
                            this.$vs.loading.close();
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
                }
            });
        },
        handleBack() {
            this.$router.push({
                name: "outbound.stock-transfer-order-processing",
            });
        },
        openTab(uri) {
            window.open(uri)
        }
    },
    watch: {
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>